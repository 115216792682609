<template>
    <div class="page-box">
        <nav-bar ref="header" header-title="新人礼包" backColor="#ffffff" :show-back="true" :header-back="newAppBack"/>
<!--        <app-page id="box" class="page-img" ref="container">-->
            <div class="my-swipe" :style="!showTime? 'margin-bottom: 32px': ''">
                <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/top_bg3.png" alt="">
<!--                <img class="customer" @click="openRule(0)"-->
<!--                     src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/rule_open2.png"-->
<!--                     alt="">-->
                <img class="top-icon" @click="jumpGift"
                     src="https://img.chaolu.com.cn/ACT/new-people-2022/top-icon.png"
                     alt="">
                <div class="top-title">活动仅针对{{ cityName }}区域</div>
            </div>
            <van-count-down v-if="showTime" @finish="timeFinish()" :time="endTime">
                <template #default="timeData">
                    <div class="countDown" >
                        <span class="colon colon-tip">距活动结束</span>
                        <span class="block">{{ timeData.days }}</span>
                        <span class="colon">天</span>
                        <span class="block">{{ timeData.hours }}</span>
                        <span class="colon">时</span>
                        <span class="block">{{ timeData.minutes }}</span>
                        <span class="colon">分</span>
                        <span class="block">{{ timeData.seconds }}</span>
                        <span class="colon">秒</span>
                    </div>
                </template>
            </van-count-down>
        <van-tabs v-model="active" scrollspy sticky :offset-top="offSetTop" line-height="0" @click="onChange">
            <van-tab :name="0" v-if="showType" title="新人专属权益">
                <div ref="title1" class="new-people row-between-center">
                    <div>新人专属权益</div>
                    <p @click="jumpNew()"><span>查看详情</span><van-icon name="arrow" /></p>
                </div>
                <div class="tab">
                    <div class="flex-none" v-for="(item, index) in infoList" :key="index">
                        <div :style="`background-image: url(${item.minPic})`">
                            <span>{{item.name}}</span>
                            <p v-if="item.voucherOverdueTime">有效期至 <br> {{ item.voucherOverdueTime }}</p>
                            <img v-if="item.receive === '0'" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/status5.png" alt="">
                            <img v-if="item.receive === '1'" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/status1.png" alt="">
                            <img v-if="item.receive === '2'" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/status2.png" alt="">
                            <img v-if="item.receive === '3'" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/status3.png" alt="">
                        </div>
                        <div @click="jumpType(item.giftType, item.receive, item.userNewcomerPowerRegularId)" class="new-people-btn row-between-center">
                            <img  v-if="item.receive === '0'" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/btn6.png" alt="">
                            <img  v-if="item.receive === '1'" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/btn2.png" alt="">
                            <img  v-if="item.receive === '2' || item.receive === '3'" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/btn1.png" alt="">
                        </div>
                    </div>
                </div>
                <!-- <img @click="goGroupEquityCard" class="go-equitycard-button" src="https://img.chaolu.com.cn/ACT/new-people-2022/month-banner.png" alt=""> -->
            </van-tab>
            <van-tab :name="1" v-if="hideGiftInfo === 1" title="购买福利加码">
                <div ref="title2" class="boon1">
                    <div class="boon1-rule">
                        <p class="ellipsis-3">{{ruleText1}}</p>
                        <div @click="openRule(1)"><span>查看详情</span><van-icon name="arrow" /></div>
                    </div>

                    <div class="boon1-btn">
                        <div @click="tabChange(1)" :class="{active: giftType === 1}">自助</div>
                        <div @click="tabChange(2)" :class="{active: giftType === 2}">团课</div>
                        <div @click="tabChange(3)" :class="{active: giftType === 3}">私教</div>
                        <div @click="tabChange(4)" :class="{active: giftType === 4}">小班课</div>
                    </div>
                </div>
                <div class="boon-list" v-if="cardList.length">
                    <div class="boon-item" v-for="(item, index) in cardList" :key="index">
                        <div>
                            <div class="gift-price">
                                <h4><span class="s1">¥</span><span class="s2">{{ item.salePrice }}</span></h4>
                                <div>{{ item.showPrice }}元</div>
                            </div>
                            <div class="gift-line"></div>
                            <div class="gift-name">
                                <div class="ellipsis-2">{{ item.name }}</div>
                                <p>有效期:{{ item.effectiveDays }}</p>
                            </div>
                        </div>
                        <img v-if="item.buyStatus === 2" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/gift_btn4.png" alt="">
                        <img v-else-if="item.buyStatus === 1" @click="buyCard(item)" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/gift_btn2.png" alt="">
                        <img v-else-if="item.buyStatus === 3" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/gift_btn3.png" alt="">
                        <img v-else @click="buyCard(item)" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/gift_btn.png" alt="">
                    </div>
                </div>
                <div class="boon-list empty col-center-center" v-else>
                    <img src="https://img.chaolu.com.cn/ACT/give-equity-card/empty.png" alt="">
                    <p>无可购买的商品</p>
                </div>
            </van-tab>
            <van-tab :name="2" v-if="taskList.length" title="运动打卡激励">
                <div ref="title3" class="boon1 boon2">
                    <div class="boon1-rule">
                        <p class="ellipsis-3">{{ruleText2}}</p>
                        <div @click="openRule(2)"><span>查看详情</span><van-icon name="arrow" /></div>
                    </div>
                </div>
                <div class="coupon-list">
                    <div  class="coupon-item-box" v-for="(item, index) in taskList" :key="index">
                        <div class="coupon-item">
                            <div>
                                <div class="coupon-price">
                                    <img :src="item.prizeImg || 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/default_punch.png'" alt="">
                                </div>
                                <div class="coupon-name">
                                    <div>{{ item.taskName }}</div>
                                    <p>{{  item.taskDescription }}</p>
                                </div>
                            </div>
                            <img @click="receiveTask(item.id)" v-if="item.receiveTaskStatus === 'WAIT_DISTRIBUTION'" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/btn3.png" alt="">
                            <img @click="goUsed(item)" v-if="item.receiveTaskStatus === 'NOT_DISTRIBUTION'" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/btn4.png" alt="">
                            <img class="coupon-used" v-if="item.receiveTaskStatus === 'DISTRIBUTION'" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/status4.png" alt="">
                        </div>
                        <div class="progress" v-if="item.receiveTaskStatus !== 'WAIT_DISTRIBUTION'">
                            <div>
                                <div v-if="item.hasTicket">自助:{{ item.userTicketNumber }}/{{ item.ticketNumber }}</div>
                                <div v-if="item.hasSchedule">团课:{{ item.userScheduleNumber }}/{{ item.scheduleNumber }}</div>
                                <div v-if="item.hasPer">私教:{{ item.userPerNumber }}/{{ item.perNumber }}</div>
                                <div v-if="item.hasVenue">到馆:{{ item.userVenueNumber }}/{{ item.venueNumber }}</div>
                            </div>
                            <div>剩余<span>{{ item.remainingDays }}</span>天</div>
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>

        <!--规则弹窗-->
        <van-popup v-model="showRule" :overlay="true" :close-on-click-overlay="true" closeable>
            <div class="rule-popup-box">
                <h3>活动规则</h3>
                <div class="rule-popup-text">
                   {{ruleText}}
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import {newAppBack, initBack, appPay, appPaySuccess, appGetDevice, gotoAppPage, defaultApp, gotoOrderList, closeWindow, appOpenWeb} from '@/lib/appMethod';
import navBar from "@/components/nav-bar/nav-bar";
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';
export default {
    components: {
        navBar
    },
    mixins: [userMixin],
    data() {
        return {
            showShare: false,
            showRule: false,
            shareItem: ['minifriend', 'postermessage', 'save'],
            shareParams: {
                title: '送你超鹿新人专属权益，快来体验',
                miniImage: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/miniImage.png',
                path: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/new-people/home&userId=1&token=1&location=1`,
                miniProgramType:
                    window.location.origin.indexOf('web.chaolu.com') > -1
                        ? '0'
                        : '2', // 正式0，开发1，体验2
                url: 'url',
                multiple: '0.95',
                userName: 'gh_7f5b57b6a76e',
            },
            swiperOption: {
                autoHeight: true, //enable auto height
                grabCursor: true,
                noSwipingClass : 'no-swiping',
            },
            venueId: '1',
            venueName: '中庚青年广场店',
            giftType: 1, // 1 自助 2 团课 3私教 5训练营
            scrollType: 1, // 1 新人 2 福利 3 打卡
            infoList: [],
            offSetTop: 0,
            container: null,
            active: 0,
            giftInfo: [],
            cardList: [],
            taskList: [], // 打卡列表
            hideGiftInfo: 0, // 隐藏购买列表
            endTime: 0, // 活动结束时间
            showTime: false, // 是否展示倒计时
            showType: false, // 是否展示新人福利
            ruleText: ``,
            ruleText1: ``,
            ruleText2: ``,
            cityName: '',
        };
    },
    methods: {
        newAppBack,
        load() {
            this.getActivity()
            this.getBoonList()
            this.getTaskList()
            wx.miniProgram.postMessage({
                data: {
                    type: 'share',
                    shareImage: this.shareParams.miniImage,
                    shareTitle: this.shareParams.title,
                    shareUrl: this.shareParams.path,
                }
            });
        },
        onChange(v) {
            let id = ''
            if(v === 0){
                id = 1499
            }else if(v === 1){
                id = 1500
            }else if(v === 2){
                id = 1501
            }else{
                return false
            }
            this.countPoint(44, 312, id, this.userId)
        },
        timeFinish() {
            this.getActivity()
        },
        openRule(index){
            if(index === 1){
                this.ruleText = this.ruleText1
            }else{
                this.ruleText = this.ruleText2
            }
            this.showRule = true
        },
        // 跳转新人专属页
        jumpNew(id, giftType) {
            if(this.appTypeStr === 'mini'){
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/new-people&userId=1&token=1&location=1&giftId=${id}&giftType=&{giftType}`,
                })
            }else{
                this.$router.push({path: '/superdeer-activity/new-people', query: {isBackToH5: 'true', userId: this.userId, token: this.token, giftId: id, giftType, cityId: this.currentCityId}})
            }
        },
        // 跳转月卡购买页
        goGroupEquityCard() {
          if(this.appTypeStr === 'mini'){
              wx.miniProgram.navigateTo({
                  url: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership?cityId=1`,
              })
          }else{
              this.$router.push({path: '/superdeer-activity/sell-membership', query: {isBackToH5: 'true', userId: this.userId, token: this.token, cityId: this.currentCityId}})
          }
        },
        // 88礼包
        jumpGift() {
            appOpenWeb('', `${window.location.origin}/#/invite-gift?userId=1`)
        },
        goUsed(item){
            if(item.hasSchedule && (item.userScheduleNumber < item.scheduleNumber)){
                gotoAppPage(3)
            }else if(item.hasPer && (item.userPerNumber < item.perNumber)){
                gotoAppPage(1, 1)
            }else{
                if(this.appTypeStr === 'mini'){
                    wx.miniProgram.switchTab({
                        url: `/pages/home/index`
                    })
                }else{
                    this.appBack()
                }
            }
        },
        jumpType(v, receive, id){
            if(receive === '0'){
                this.jumpNew(id, v)
                return false
            }else if(receive === '2' || receive === '3'){
                return false
            }
            if(v === '1'){
                if(this.appTypeStr === 'mini'){
                    wx.miniProgram.switchTab({
                        url: `/pages/home/index`
                    })
                }else{
                    closeWindow()
                }
            }else if(v === '2'){
                gotoAppPage(3)
            }else if(v === '3'){
                gotoAppPage(1, 1)
            }else if(v === '5'){
                gotoAppPage(8)
            }
        },
        async getActivity() {
            let deviceId = ''
            try {
                deviceId = await appGetDevice()
            }catch (e){
                // deviceId = 'YekPEv/JI+gDAM2tVNtCsq0S'
                console.log(e)
            }
            console.log('deviceId', deviceId)
            let sourcePlatform = 0
            if(this.appTypeStr === 'and'){
                sourcePlatform = 2
            }else if(this.appTypeStr === 'ios'){
                sourcePlatform = 1
            }else if(this.appTypeStr === 'mini'){
                sourcePlatform = 3
            }
            this.$axios.post(`${this.baseURLApp}/app/newcomerPower/activity`, {
                cityId: this.currentCityId,
                userId: this.userId,
                deviceId: deviceId,
                sourcePlatform
            }).then(res => {
                let data = res.data
                if(data.showType === '0'){
                    this.$dialog.alert({
                        message: ' <b>您已经是超鹿的老朋友了<br/>去看看其他活动吧</b>',
                        confirmButtonColor: '#267DFF',
                        confirmButtonText: '我知道了',
                        className: 'dialog',
                    }).then(() => {
                        if(this.appTypeStr === 'mini'){
                            wx.miniProgram.switchTab({
                                url: `/pages/home/index`
                            })
                        }else{
                            this.appBack()
                        }
                    })
                }else{
                    if(data.endTime){
                        if((data.endTime - new Date().getTime()) > 0){
                            this.endTime = (data.endTime - new Date().getTime())
                            this.showTime = true
                        }else{
                            this.$dialog.alert({
                                message: ' <b>活动结束了<br/>去看看其他活动吧</b>',
                                confirmButtonColor: '#267DFF',
                                confirmButtonText: '我知道了',
                                className: 'dialog',
                            }).then(() => {
                                if(this.appTypeStr === 'mini'){
                                    wx.miniProgram.switchTab({
                                        url: `/pages/home/index`
                                    })
                                }else{
                                    this.appBack()
                                }
                            })
                        }
                    }
                    this.showType = data.showType === '1' || data.showType === '2'
                    this.selfInfo = data.list.find(v=>{return v.giftType === '1'})
                    this.courseInfo = data.list.find(v=>{return v.giftType === '2'})
                    this.coachInfo = data.list.find(v=>{return v.giftType === '3'})
                    this.campInfo = data.list.find(v=>{return v.giftType === '5'})
                    this.infoList = [this.selfInfo, this.courseInfo, this.coachInfo]
                    if (this.campInfo) {
                        this.infoList.push(this.campInfo)
                    }
                }
            })
        },
        getTaskList() {
            this.$axios.post(`${this.baseURLApp}/newUser/activity/user/task`, {
                cityId: this.currentCityId,
                userId: this.userId,
                token: this.token,
                "hasWx": true,
            }).then(res => {
                this.taskList = res.data || []
                if(this.taskList.length){
                    this.ruleText2 = this.taskList[0].rule
                }
                this.active = 0
            })
        },
        receiveTask(taskId) {
            this.$dialog.confirm({
                title: '领取提示',
                message: '只能领取一个新人打卡任务，\n确认领取吗？',
                confirmButtonText: '确认领取',
                cancelButtonText: '暂不领取',
                confirmButtonColor: '#267DFF',
            }).then(() => {
                this.$axios.post(`${this.baseURLApp}/newUser/activity/user/receive`, {
                    userId: this.userId,
                    token: this.token,
                    "hasWx": true,
                    taskId
                }).then(res => {
                    this.getTaskList()
                })
            }).catch(() => {
                    // on cancel
            });

        },
        tabChange(v) {
            this.giftType = v
            // this.cardList = this.giftInfo.find(v=>{return v.line === this.giftType}).cardList
            let obj = this.giftInfo.find(v=>{return v.line === this.giftType}) || {}
            let cardList = obj.cardList || []
            this.cardList =  cardList.map(v => {
                v.type = 12
                return v
            })
            let packageList = obj.packageList || []
            packageList = packageList.map(v => {
                v.type = 10
                return v
            })
            this.cardList.push(...packageList)
            let id = ''
            if(v === 1){
                id = 1502
            }else if(v === 2){
                id = 1503
            }else if(v === 3){
                id = 1504
            }else{
                return false
            }
            this.countPoint(44, 312, id, this.userId)
        },
        dealUrl(v){
            if (!v || v.indexOf('http') < 0) {
                return ('http://img.chaolu.com.cn'+v);
            } else {
                return v;
            }
        },
        // 处理图片加载失败时间
        errorFun(e){
            try {
                e.target.src = 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/coach/logo.jpg'
            }catch (e){
                console.log('图片处理报错')
            }
        },
        getBoonList() {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.$axios.post(`${this.baseURLApp}/newUserBenefit/userList`, {
                cityId: this.currentCityId,
                userId: this.userId
            }).then(res => {
                this.giftInfo = res.data.list || []
                this.hideGiftInfo = res.data.hide || 0
                this.ruleText1 = res.data.activityRule || ''
                this.benefitConfigId = res.data.benefitConfigId || ''
                let obj = this.giftInfo.find(v=>{return v.line === this.giftType}) || {}
                let cardList = obj.cardList || []
                this.cardList =  cardList.map(v => {
                    v.type = 12
                    return v
                })
                let packageList = obj.packageList || []
                packageList = packageList.map(v => {
                    v.type = 10
                    return v
                })
                this.cardList.push(...packageList)
                this.$forceUpdate()
                this.$toast.clear()
                this.active = 0
            }).catch(r => {
                this.$toast.clear()
            })
        },
        buyCard(info){
           if(info.buyStatus === 1){
                this.$toast('您已有待支付订单，快去支付吧！')
               this.timer1 && clearTimeout(this.timer1)
               this.timer1 = setTimeout(() => {
                    gotoOrderList()
                }, 2500)
                return false
            }
            let url = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/new-people/home&userId=1&token=1&location=1`
            let payData = {
                subject: info.name,
                extendsJson: {
                    appVersion: '2.12.11',
                    seriesName: info.name,
                    itemId: info.itemId,
                    benefitConfigId: this.benefitConfigId,
                    benefitConfigDetailId: info.benefitConfigDetailId,
                },
                totalAmount: info.salePrice,
                venueId: '31',
                // goodsNum: 1,
                goodsNum: info.type === 12 ? '1' : undefined,
                isApp: '1',
                type: info.type,
                userId: this.userId,
                token: this.token,
                extendsType: '4',
                mouldId: info.itemId,
                whetherPrivacy: 0,
                originalPriceStr:'',
                title: '新人福利',
            }
            if(this.appTypeStr === 'mini'){
                payData.wxPayType = 'applet'
            }
            appPay(payData, url)
            this.timer && clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                if(this.userId && this.token){
                    this.load()
                }
            }, 5000)
            if( this.appTypeStr !== 'mini' ){
                appPaySuccess().then(res=>{
                    if(this.userId && this.token){
                        this.load()
                    }
                }).catch(() => {
                    if(this.userId && this.token){
                        this.load()
                    }
                })
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.offSetTop = this.$refs.header.$el.offsetHeight
        }, 1000)
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                if(this.userId && this.token){
                    this.load()
                }
            }else if(document.visibilityState === "hidden"){
                this.showRule = false
                console.log('hidden')
            }

        })
    },
    async created() {
        initBack();
        await this.$getAllInfo(['userId', 'token', 'cityId'])

        this.currentCityId = this.cityId || '3'

        if(this.currentCityId == '3'){
            this.cityName = '福州'
        }else if(this.currentCityId == '036652995ac5451fbb6791c3491e1c2d') {
            this.cityName = '厦门'
        }else if(this.currentCityId == '818447834079563776') {
            this.cityName = '广州'
        }
        if (this.userId) {
          this.load()
        } else {
          this.$store.commit('openLoginPopup', true)
        }
        appPaySuccess().then(res => {
            this.showPaySuccess = true
            if (this.userId && this.token) {
                // this.countPoint('40', '301', '1419')
            }
        })
        this.countPoint(44, 312, 1516, this.userId)
    },
};
</script>

<style scoped lang="less">
.page-box {
    background: #FDEDC7;
    min-height: 100vh;
    padding-bottom: 90px;
}
.boon1.boon2{
    height: 314px;
    background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/bg3.png");
    margin-top: 40px;
}
.coupon-list{
    width: 686px;
    margin: 0 auto;
    .coupon-item-box{
        padding: 28px;
        background-color: white;
        margin-top: 16px;
        border-radius: 16px;
        .progress{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 22px;
            border-top: 2px solid #FAEDCF;
            &>div{
                font-size: 24px;
                //line-height: 34px;
                height: 34px;
                color: #3C454E;
                display: flex;
                align-items: center;
                div{
                    white-space: nowrap;
                    margin-right: 16px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
                &:first-child{
                    flex-grow: 1;
                    display: flex;
                    //justify-content: space-between;
                    background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/icon1.png") no-repeat left center;
                    background-size: 32px 32px;
                    padding-left: 40px;
                }
                &:last-child{
                    width: 120px;
                    justify-content: flex-end;
                    white-space: nowrap;
                    span{
                        color: #FF6E00;
                    }
                }
            }
        }
    }
    .coupon-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &>div{
            display: flex;
            align-items: center;
            .coupon-price{
                margin-right: 12px;
                img{
                    width: 120px;
                    height: 122px;
                }
            }
            .coupon-name{
                max-width: 340px;
                div{
                    color: #3C454E;
                    margin-bottom: 16px;
                    font-weight: bold;
                    font-size: 28px;
                    line-height: 28px;
                }
                p{
                    color: #3C454E;
                    font-size: 22px;
                    line-height: 32px;
                }
            }
        }
        img{
            width: 152px;
            height: 88px;
        }
        .coupon-used{
            width: 104px;
            height: 104px;
        }
    }
}
.boon1{
    width: 686px;
    height: 394px;
    margin: 0 auto;
    background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/bg1.png") no-repeat center center;
    background-size: 100% 100%;
    border-radius: 16px 16px 0 0;
    padding: 104px 28px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 40px;
    .boon1-rule{
        padding: 28px;
        background: #FFFFFF;
        border-radius: 16px;
        min-height: 180px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div{
            color: #267DFF;
            font-size: 24px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        p{
            font-size: 24px;
            color: #3C454E;
            line-height: 34px;
            white-space: pre-line;
        }
    }
    .boon1-btn{
        display: flex;
        justify-content: space-between;
        div{
            width: 184px;
            height: 80px;
            color: #EE7998;
            font-weight: bold;
            font-size: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px solid transparent;
            border-bottom: none;
            &.active{
                border-color: #FFBDE2;
                border-radius: 20px 20px 0 0;
                color: #F7255D;
                background-color: white;
            }
        }
    }
}
.boon-list{
    width: 686px;
    margin: 0 auto;
    background-color: white;
    border-radius: 0 0 16px 16px;
    padding: 32px 28px;
    box-sizing: border-box;
    &.empty{
        height: 400px;
        p{
            color: #6C727A;
            font-size: 24px;
            margin-top: 22px;
        }
        img{
            width: 200px;
            height: 200px;
        }
    }
    .boon-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/bg2.png") no-repeat center center;
        background-size: 100% 100%;
        padding: 34px 24px;
        &.boon-item + .boon-item{
            margin-top: 20px;
        }
        &>div{
            display: flex;
            align-items: center;
            .gift-price{
                color: #7F0B05;
                h4{
                    font-family: BebasNeueBold;
                    display: flex;
                    align-items: flex-end;

                    .s1{
                        font-size: 32px;
                    }
                    .s2{
                        line-height: 60px;
                        font-size: 76px;
                    }
                }
                div{
                    text-decoration: line-through;
                    font-size: 22px;
                    color: #7F0B05;
                    line-height: 22px;
                    margin-top: 6px;
                }
            }
            .gift-name{
                max-width: 280px;
                div{
                    font-weight: bold;
                    line-height: 44px;
                    font-size: 28px;
                    color: #772525;
                }
                p{
                    margin-top: 12px;
                    font-size: 20px;
                    color: #F7255D;
                    line-height: 34px;
                    white-space: nowrap;
                }
            }
            .gift-line{
                border-right: 1px #7F0B05 dashed;
                height: 72px;
                width: 0;
                margin: 0 16px 0 10px;
            }
        }
        img{
            width: 128px;
            height: 64px;
        }
    }
}
.tab{
    width: 686px;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    &>div{
        width: 206px;
        margin: 0 12px 0 0;
        .new-people-btn{
            margin: 0 auto;
            img{
                width: 100%;
            }
        }
        &>div:first-child{
            background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/nav1.png") no-repeat center center;
            background-size: cover;
            height: 160px;
            border-radius: 12px;
            box-sizing: border-box;
            border: 2px solid #FDEDC7;
            position: relative;
            padding: 12px 16px 4px 16px;
            color: #3C454E;
            font-size: 24px;
            font-weight: bold;
            line-height: 34px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            &>p{
                color: #6C727A;
                font-size: 16px;
                line-height: 24px;
                font-weight: normal;
            }
            img{
                width: 60px;
                height: 60px;
                position: absolute;
                left: 70px;
                bottom: 52px;
            }
            &.active{
                border-color: #FF6E00;
            }
        }
    }
}
.go-equitycard-button {
  display: block;
  width: 686px;
  margin: 32px auto 40px;
}
.new-people-nav{
    width: 100vw;
    height: 84px;
    //padding:0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, #FF8A7E 0%, #FF3A48 100%);
    border-radius: 8px;
    font-size: 28px;
    color: #FFFFFF;
    div{
        width: 33.33%;
        text-align: center;
        height: 84px;
        line-height: 84px;
        &.active{
            font-weight: bold;
            border-radius: 8px;
            background: linear-gradient(180deg, #FFEE53 0%, #FD7019 100%);
        }
    }
}
.new-people{
    padding: 64px 32px 24px;
    div{
        font-family: PuHuiTi;
        font-size: 32px;
        color: #D03213;
        line-height: 32px;
    }
    p{
        color: #D03213;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        align-items: center;
    }
}
.my-swipe {
    position: relative;
    margin-bottom: 24px;
    img{
        width: 100%;
    }
    .customer {
        width: 112px;
        height: 48px;
        position: absolute;
        right: 0;
        top: 140px;
    }
    .top-icon{
        width: 140px;
        height: 130px;
        position: absolute;
        right: 16px;
        bottom: 140px;
    }
    .top-title{
        position: absolute;
        left: calc(50% - 182px);
        bottom: 0;
        width: 364px;
        text-align: center;
        background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/top_bg3_1.png") no-repeat left center,
                    url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/top_bg3_2.png") right center no-repeat;
        background-size: 44px 24px;
        font-size: 28px;
        font-weight: bold;
        color: #D03213;
    }
}
.countDown{
    width: 686px;
    height: 80px;
    border-radius: 16px;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-bottom: 30px;
    .colon{
        font-size: 24px;
        font-weight: 400;
        color: #D03213;
        &.colon-tip{
            margin-right: 8px;
        }
    }
    .block{
        width: 48px;
        height: 48px;
        background: linear-gradient(180deg, #FFAC6E 0%, #FE6E00 100%);
        border-radius: 8px;
        font-size: 32px;
        font-family: BebasNeueBold;
        color: #ffffff;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 16px;
        padding-top: 6px;
        box-sizing: border-box;
    }
}

/deep/ .van-tabs__wrap{
    width: 100vw;
    border-radius: 8px;
    padding: 0 32px;
    box-sizing: border-box;
    .van-tabs__nav{
        background: linear-gradient(180deg, #FF8A7E 0%, #FF3A48 100%);
        border-radius: 8px;
    }
    .van-tabs__nav--line{
        padding-bottom: 0;
    }
    .van-tab{
        background-color: transparent;
        color: white;
        font-size: 28px;
    }
    .van-tab--active{
        font-weight: bold;
        border-radius: 8px;
        background: linear-gradient(180deg, #FFEE53 0%, #FD7019 100%);
    }
}
.rule-popup-box{
    width: 654px;
    box-sizing: border-box;
    padding-bottom: 24px;
    h3{
        text-align: center;
        padding: 32px 0;
    }
    .rule-popup-text{
        width: 100%;
        //min-height: 400px;
        max-height: 650px;
        display: block;
        padding: 0 48px;
        box-sizing: border-box;
        overflow-y: auto;
        font-size: 24px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        white-space: pre-line;
    }
}
</style>
